<template>
    <div class="modal" v-if="modelValue" :class="{active : modelValue}">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1 v-if="!hasUrl">{{ title }} <span>Get {{ title }} Link</span></h1>
                    <h1 v-else>{{ title }} <span>Shorten  Link</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <div class="form_grp" v-if="hasOptinLink">
                        <div class="group_item">
                            <label class="input_label">Optin page link</label>
                            <div class="field_wpr has_suffix">
                                <input type="text" name="name" v-model="optinUrl" readonly />
                                <span class="suffix" @click="copyOptinLink">Copy</span>
                            </div>
                        </div>
                    </div>
                    <div class="form_grp">
                        <div class="group_item">
                            <label class="input_label" v-if="!hasUrl">{{ title }} link:</label>
                            <label class="input_label" v-else>Shorten Link</label>
                            <div class="field_wpr has_suffix">
                                <input type="text" name="name" v-model="url" readonly />
                                <span class="suffix" @click="copyLink">Copy</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal_footer">
                <button type="button" class="btn cancel_btn" @click="closeModal">Cancel</button>
                <!-- <button type="button" class="btn save_btn" @click="copyLink">Copy</button> -->
            </div>
        </div>
    </div>
</template>

<script>
    import Toastr from '@/utils/Toastr'

    import { copyText } from 'vue3-clipboard'
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Asset Link',

        data () {
            return {
                url: 'Fetching...',
                optinUrl: 'Fetching...',
                titleList: [
                    'Habit Scenario',
                    'Progress Scenario',
                    'Client Tracking',
                    'Newsletter',
                    'Workout',
                    'Plan',
                ],
            }
        },

        props: {
            modelValue: Boolean,
            title: String,
            selectedAsset: Object,
            hasUrl: {
                type: Boolean,
                default: false
            },
            module: {
                type: String,
                default: ''
            },
            hasOptinLink: {
                type: Boolean,
                default: false,
            }
        },

        emit: ['update:modelValue'],

        watch: {
            modelValue (value) {
                if (value) {
                    const vm = this;

                    if ((vm.titleList.includes(vm.title) || vm.hasUrl)) {
                        vm.url = vm.selectedAsset.url;
                    } else if (vm.title == 'Broadcast') {
                        const baseUrl = window.location.origin;
                        vm.url = `${baseUrl}/broadcast/${vm.selectedAsset.id}/public-view`;
                    } else {
                        const params = {
                            destination: vm.selectedAsset.share_link,
                        };

                        vm.getShortUrl(params);
                    }

                    if (vm.hasOptinLink) {
                        vm.optinUrl = vm.selectedAsset.opt_in_url;
                    }

                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            shortenUrl (shortenUrl) {
                const vm = this;

                vm.url = shortenUrl;
            },

            optinShortenUrl (shortenUrl) {
                const vm = this;

                vm.optinUrl = shortenUrl;
            },
        },

        computed: mapState({
            shortenUrl: state => state.commonModule.shortenUrl,
            optinShortenUrl: state => state.commonModule.optinShortenUrl,
        }),

        methods: {
            ...mapActions({
                getShortUrl: 'commonModule/getShortUrl',
                getOptinShortUrl: 'commonModule/getOptinShortUrl',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            copyLink () {
                const vm = this;

                copyText(vm.url, undefined, (error, event) => {
                    if (error) {
                        Toastr.error('Failed to copy link!');
                    } else {
                        Toastr.success('Link copied successfully!');
                        vm.closeModal();
                    }
                });
            },

            copyOptinLink () {
                const vm = this;

                copyText(vm.optinUrl, undefined, (error, event) => {
                    if (error) {
                        Toastr.error('Failed to copy link!');
                    } else {
                        Toastr.success('Link copied successfully!');
                        vm.closeModal();
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .field_wpr.has_suffix .suffix {
        background: #2f7eed;
        color: #fff;
        border-radius: 0 6px 6px 0;
        cursor: pointer;
    }
</style>